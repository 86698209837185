import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const BuySeller = () => {
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta cta-2 item1">
              <i className="flaticon-sales-agent"></i>
              <div className="cta-body">
                <h4>Are you looking for quality services?</h4>
                <p>
                  We guarantee quality and seriousness at all times. We provide
                  a wide variety of offers that will fit your needs and you will
                  be more than satisfied with our mechanics staff.
                </p>
                <OutboundLink href="/about" className="btn-link">
                  Read More <i className="fas fa-arrow-right"></i>{" "}
                </OutboundLink>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cta cta-2 item2">
              <i className="flaticon-bicycle"></i>
              <div className="cta-body">
                <h4>Do you want to ask us a question?</h4>
                <p>
                  You can consult our team of experts who will clarify all your
                  doubts and give you the best advice. If you don't know how to
                  do it, here we tell you how to find us.
                </p>
                <OutboundLink href="/contact" className="btn-link">
                  Read More <i className="fas fa-arrow-right"></i>{" "}
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySeller;
