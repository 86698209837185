import React from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";

const CustomToggle = ({ children, eventKey, setActive, active }: any) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setActive(eventKey);
    if (active === eventKey) {
      setActive(null);
    }
  });

  return (
    <div
      className="card-header"
      data-toggle="collapse"
      role="button"
      data-target="#faqFour"
      aria-expanded={active === eventKey ? "true" : "false"}
      aria-controls="faqFour"
      onClick={(e) => decoratedOnClick(e)}
    >
      {children}
    </div>
  );
};

type CardProp = {
  eventKey: string;
  active: string;
  setActive: any;
  title: string;
  body: string;
};
const Card = ({ eventKey, active, setActive, title, body }: CardProp) => {
  return (
    <div className="card">
      <CustomToggle eventKey={eventKey} setActive={setActive} active={active}>
        {title}
      </CustomToggle>

      <Accordion.Collapse
        eventKey={eventKey}
        id="acc-show"
        className={`collapse ${active === eventKey ? "show" : ""}`}
        data-parent="#faqAccordions2"
      >
        <div className="card-body">
          <p className="mb-0">{body}</p>
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default Card;
