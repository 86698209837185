import { useMemo, useRef } from 'react';
import * as React from 'react';
import { FormProvider } from '@/hook-form';
import RHInput from '@/hook-form/RHInput';
import RHTextarea from '@/hook-form/RHTextarea';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { saveContact, ContactData } from '@/npc-api/contact';
import Acc from './Accordion';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useConfig } from '@/hooks/useConfig';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

const Contact = () => {
  const { captchaSiteKey } = useConfig();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string(),
    message: Yup.string().required('Message is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      company: '',
      message: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: ContactData) => {
    if (!recaptchaRef.current?.getValue()) {
      toast.error(
        'Please check the Captcha field to validate you are not a robot'
      );
      return;
    }

    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    });

    await saveContact(data).then((e) =>
      reset({ name: '', phone: '', email: '', company: '', message: '' })
    );
    recaptchaRef.current?.reset();
  };
  return (
    <div className="section pt-0">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">Contact Us</h5>
          <h2 className="title">Stay Connected</h2>
        </div>

        <div className="row">
          <div className="col-lg-7">
            <div className="comment-form mb-lg-30">
              <FormProvider
                name="fwdedamsn"
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
                className="atf-contact-form form"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Full Name</label>
                    <RHInput
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Email Address</label>
                    <RHInput
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Your Email"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Phone</label>
                    <RHInput
                      name="phone"
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Company</label>
                    <RHInput
                      name="company"
                      type="text"
                      className="form-control"
                      placeholder="Company"
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label>Your Message</label>
                    <RHTextarea
                      className="form-control"
                      name="message"
                      placeholder="Your Message"
                      rows={5}
                    />
                  </div>
                </div>

                {captchaSiteKey && (
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <ReCAPTCHA
                        sitekey={captchaSiteKey}
                        ref={recaptchaRef}
                        size="normal"
                      />
                    </div>
                  </div>
                )}

                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn-custom primary"
                  name="button"
                >
                  {isSubmitting ? '...Loading' : 'Send Message'}
                </button>
              </FormProvider>
            </div>
          </div>
          <Acc />
        </div>
      </div>
    </div>
  );
};

export default Contact;
