export const data = [
  {
    id: '0',
    title: "Where Do Cycleing Rentals Oprates ?",
    body: "A bicycle-sharing system, public bicycle scheme or public bike share PBS scheme is a shared transport service in which bicycles are made available for shared use to individuals on a short term basis for a price or free. Many bike share systems.",
  },
  {
    id: '1',
    title: "​Is Delivery And Collection Included ?",
    body: "A bicycle-sharing system, public bicycle scheme or public bike share PBS scheme is a shared transport service in which bicycles are made available for shared use to individuals on a short term basis for a price or free. Many bike share systems.",
  },
  {
    id: '2',
    title: "​How Do I Rent A Bicycle ?",
    body: "A bicycle-sharing system, public bicycle scheme or public bike share PBS scheme is a shared transport service in which bicycles are made available for shared use to individuals on a short term basis for a price or free. Many bike share systems.",
  },
];
