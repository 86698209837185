import React from "react";
import { Accordion } from "react-bootstrap";
import Card from "@/components/Home/Contact/Card";
import { data } from "@/components/Home/Contact/data";

const Acc = () => {
  const [active, setActive] = React.useState("0");
  return (
    <div className="col-lg-5">
      <div className="accordion with-gap" id="faqAccordions2">
        <Accordion defaultActiveKey="0">
          {data.map((item, index) => {
            return (
              <Card
                key={index}
                eventKey={item.id}
                active={active}
                setActive={setActive}
                title={item.title}
                body={item.body}
              />
            );
          })}
        </Accordion>
        {/* <div className="card">
      <div className="card-header" data-toggle="collapse" role="button" data-target="#faqFour" aria-expanded="true" aria-controls="faqFour">
        Where Do Cycleing Rentals Oprates ?
      </div>

      <div id="faqFour" className="collapse show" data-parent="#faqAccordions2">
        <div className="card-body">
          <p className="mb-0">
            A bicycle-sharing system, public bicycle scheme or public bike share PBS scheme is a shared transport service in which bicycles are made available for shared use to individuals on a short term basis for a price or free. Many bike share systems.
          </p>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header" data-toggle="collapse" role="button" data-target="#faqFive" aria-expanded="false" aria-controls="faqFive">
        ​Is Delivery And Collection Included ?
      </div>

      <div id="faqFive" className="collapse" data-parent="#faqAccordions2">
        <div className="card-body">
          <p className="mb-0">
            A bicycle-sharing system, public bicycle scheme or public bike share PBS scheme is a shared transport service in which bicycles are made available for shared use to individuals on a short term basis for a price or free. Many bike share systems.
          </p>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header" data-toggle="collapse" role="button" data-target="#faqSix" aria-expanded="false" aria-controls="faqSix">
        ​How Do I Rent A Bicycle ?
      </div>

      <div id="faqSix" className="collapse" data-parent="#faqAccordions2">
        <div className="card-body">
          <p className="mb-0">
            A bicycle-sharing system, public bicycle scheme or public bike share PBS scheme is a shared transport service in which bicycles are made available for shared use to individuals on a short term basis for a price or free. Many bike share systems.
          </p>
        </div>
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default Acc;
