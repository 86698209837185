import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "@/components/Home/Subscriber/Carousel/styles.css"

import { useViewport } from "@/hooks/useViewport";
// import required modules
import { Pagination, Autoplay } from "swiper";

export default function Sw() {
  const { isMobile, isTablet, isDesktop, isWidescreen } = useViewport();

  return (
    <>
      <Swiper
        slidesPerView={
          (isMobile && 1) ||
          (isTablet && 2) ||
          (isDesktop && 3) ||
          (isWidescreen && 4) ||
          1
        }
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
        }}
        modules={[Pagination, Autoplay]}
        className="acr-featured-listings banner-featured-slider p-t-b"
      >
        <SwiperSlide>
          <div className="acr-featured-listing">
            <div className="featured-listing-thumb">
              <a href="#">
                <img src="images/featured-listings/1.jpg" alt="listing" />
              </a>
              <div className="featured-listing-controls">
                <span>Featured</span>
                <a href="#">
                  <i className="far fa-heart"></i>
                </a>
              </div>
            </div>
            <div className="featured-listing-content">
              <h6>300$/Month</h6>
              <div className="featured-listing-meta">
                <p>Hybrid , Racing , Non-Hybrid</p>
              </div>
              <span>
                {" "}
                <i className="fas fa-map-marker-alt"></i> Santa Cruz Bike{" "}
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="acr-featured-listing">
            <div className="featured-listing-thumb">
              <a href="#">
                <img src="images/featured-listings/2.jpg" alt="listing" />
              </a>
              <div className="featured-listing-controls">
                <span>Featured</span>
                <a href="#">
                  <i className="far fa-heart"></i>
                </a>
              </div>
            </div>
            <div className="featured-listing-content">
              <h6>100$/Month</h6>
              <div className="featured-listing-meta">
                <p>Hybrid , Racing , Non-Hybrid</p>
              </div>
              <span>
                <i className="fas fa-map-marker-alt"></i> Colnago Bike{" "}
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="acr-featured-listing">
            <div className="featured-listing-thumb">
              <a href="#">
                <img src="images/featured-listings/3.jpg" alt="listing" />
              </a>
              <div className="featured-listing-controls">
                <span>Featured</span>
                <a href="#">
                  <i className="far fa-heart"></i>
                </a>
              </div>
            </div>
            <div className="featured-listing-content">
              <h6>200$/Month</h6>
              <div className="featured-listing-meta">
                <p>Hybrid , Racing , Non-Hybrid</p>
              </div>
              <span>
                <i className="fas fa-map-marker-alt"></i> Kona Bike{" "}
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="acr-featured-listing">
            <div className="featured-listing-thumb">
              <a href="#">
                <img src="images/featured-listings/4.jpg" alt="listing" />
              </a>
              <div className="featured-listing-controls">
                <span>Featured</span>
                <a href="#">
                  <i className="far fa-heart"></i>
                </a>
              </div>
            </div>
            <div className="featured-listing-content">
              <h6>100$/Month</h6>
              <div className="featured-listing-meta">
                <p>Hybrid , Racing , Non-Hybrid</p>
              </div>
              <span>
                <i className="fas fa-map-marker-alt"></i> Schwinn Bike{" "}
              </span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
