import { saveSubscriber } from '@/npc-api/subscriber';
import React, { useState } from 'react';
import Carousel from './Carousel';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

const Subscriber = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    GTM.sendEvent({
      event: GTMEventType.RequestSubscription,
    });

    await saveSubscriber({ email })
      .then((e) => {
        setEmail('');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <div className="container">
      <div className="banner-item">
        <div className="banner-inner">
          <div className="banner-text">
            <div className="acr-dots-wrapper">
              <div className="acr-dots"></div>
            </div>
            <h1 className="title">Find Your Ideal Cyclo Agent </h1>
            <p className="subtitle">
              Thousands of people want to sell or buy. Don't miss your chance to
              grab your dream cycle.
            </p>
          </div>
          <div className="banner-newsletter">
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email Address"
                className="form-control"
                name="email-newsletter"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn-custom secondary"
                name="button"
              >
                Subscribe
              </button>
            </form>
            <span>
              *We will be sending you two emails per month about offers and
              exclusive listings
            </span>
          </div>
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default Subscriber;
