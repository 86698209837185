import React from "react";
import Subscriber from "@/components/Home/Subscriber";
import About from "@/components/Home/About";
import Services from "@/components/Home/Services";
import BuySeller from "@/components/Home/BuySeller";
import Testimonials from "@/components/Home/Testimonials";
import Contact from "@/components/Home/Contact";


const Home = () => {
  return (
    <>
      <div className="banner banner-4 bg-cover bg-center">
        <Subscriber />
        <div
          className="banner-bg bg-center bg-cover"
          style={{ backgroundImage: "url('images/banner/4.jpg')" }}
        ></div>
      </div>
      <About />
      <Services/>
      <BuySeller/>
      <Testimonials/>
      <Contact/>
    </>
  );
};

export default Home;
